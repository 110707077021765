import axios from "axios"
import { IPatient, IPatientForm } from "../../types/models/Patient"
import { client } from "./ApiClient"


export const PaymentsAPI = {
  index: async (): Promise<IPatient[]> => {
    const res = await client.get("/payments")
    return res.data
  },
  show: async (id: number | string): Promise<IPatient> => {
    const res = await client.get(`/payments/${id}`)
    return res.data
  },
  create: async (patient: IPatientForm): Promise<IPatient> => {
    const res = await client.post("/payments", patient)
    return res.data
  },
  update: async (patient: IPatientForm): Promise<IPatient> => {
    const res = await client.put(`/payments/${patient.id}`, patient)
    return res.data
  },
  delete: async (id: number | string): Promise<void> => {
    await client.delete(`/payments/${id}`)
  },

}