import { IAppointment, IAppointmentForm, IEventForm } from "../../types/models/Appointment"
import { client } from "./ApiClient"


export const AppointmentsAPI = {
  index: async (data: any): Promise<IAppointment[]> => {


    const res = await client.get("/sessions", { params: data })
    return res.data
  },
  show: async (id: number | string): Promise<IAppointment & { title: string }> => {
    const res = await client.get(`/sessions/${id}`)
    return res.data
  },
  create: async (appointment: IEventForm): Promise<IAppointment> => {
    const res = await client.post("/sessions", appointment)
    return res.data
  },
  update: async (appointment: IAppointmentForm): Promise<IAppointment> => {
    const res = await client.put(`/sessions/${appointment.id}`, appointment)
    return res.data
  },
  delete: async (id: number | string): Promise<void> => {
    await client.delete(`/sessions/${id}`)
  }
}