import { IPatient, IPatientForm } from "../../types/models/Patient"
import { client } from "./ApiClient"
import { AutoComplete } from "../../types/common/AutoComplete"

export const PatientsAPI = {
  index: async (params: string | null = null): Promise<IPatient[]> => {
    let res
    if (params) {
      res = await client.get("/patients?q=" + params)
    }
    else {
      res = await client.get("/patients")
    }
    return res.data
  },
  show: async (id: number | string): Promise<IPatient> => {
    const res = await client.get(`/patients/${id}`)
    return res.data
  },
  create: async (patient: IPatientForm): Promise<IPatient> => {
    const res = await client.post("/patients", patient)
    return res.data
  },
  update: async (patient: IPatientForm): Promise<IPatient> => {
    const res = await client.put(`/patients/${patient.id}`, patient)
    return res.data
  },
  delete: async (id: number | string): Promise<void> => {
    await client.delete(`/patients/${id}`)
  },
  autocomplete: async (query: string): Promise<AutoComplete[]> => {
    const res = await client.get(`/autocomplete/patients?term=${query}`)
    return res.data
  }
}