import { IPatient } from "../../types/models/Patient"
import { client } from "./ApiClient"
import { IDoctor, IDoctorForm } from "../../types/models/Doctor"
import { AutoComplete } from "../../types/common/AutoComplete"

const ENDPOINT = '/doctors'
export const DoctorsAPI = {
  index: async (params: string | null = null): Promise<IDoctor[]> => {
    let res
    if (params) {
      res = await client.get(`${ENDPOINT}?q=` + params)
    }
    else {
      res = await client.get(ENDPOINT)
    }
    return res.data
  },
  show: async (id: number | string): Promise<IDoctor> => {
    const res = await client.get(`${ENDPOINT}/${id}`)
    return res.data
  },
  create: async (doctor: IDoctorForm): Promise<IPatient> => {
    const res = await client.post(ENDPOINT, { doctor })
    return res.data
  },
  update: async (doctor: IDoctorForm): Promise<IDoctor> => {
    const res = await client.put(`${ENDPOINT}/${doctor.id}`, doctor)
    return res.data
  },
  delete: async (id: number | string): Promise<void> => {
    await client.delete(`${ENDPOINT}/${id}`)
  },
  autocomplete: async (query: string): Promise<AutoComplete[]> => {
    const res = await client.get(`/autocomplete/${ENDPOINT}?term=${query}`)
    return res.data
  }
}