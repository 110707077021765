import axios from "axios";
import { AppointmentsAPI } from "./Appointments";
import { PatientsAPI } from "./Patients";
import { PaymentsAPI } from "./Payments";

import { useNavigation } from "../../pages/auth/NavigationContext";
import { TreatmentsAPI } from "./Treatments";
import { DoctorsAPI } from "./Doctors";
import { AccountsAPI } from "./Accounts";
export const client = axios.create({ baseURL: "/api" })
client.interceptors.request.use(
  (config) => {
    // Get the token from localStorage (or sessionStorage)

    const token = localStorage.getItem("jwt");

    // If the token exists, add it to the Authorization header
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => response, // If response is okay, just return it
  (error) => {
    if (error.response && error.response.status === 401) {

      localStorage.removeItem("jwt");
      localStorage.removeItem("doctor");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);


export const ApiClient = {
  patients: PatientsAPI,
  appointments: AppointmentsAPI,
  payments: PaymentsAPI,
  treatments: TreatmentsAPI,
  doctors: DoctorsAPI,
  accounts: AccountsAPI
}