
import { ITreatment } from "../../types/models/Treatments"
import { client } from "./ApiClient"

export const TreatmentsAPI = {
  index: async (): Promise<ITreatment[]> => {
    const res = await client.get("/treatments")
    return res.data
  },
  show: async (id: number | string): Promise<ITreatment> => {
    const res = await client.get(`/treatments/${id}`)
    return res.data
  },
  create: async (treatment: Omit<ITreatment, 'id'>): Promise<ITreatment> => {
    const res = await client.post("/treatments", treatment)
    return res.data
  },
  update: async (treatment: ITreatment): Promise<ITreatment> => {
    const res = await client.put(`/treatments/${treatment.id}`, treatment)
    return res.data
  },
  delete: async (id: number | string): Promise<void> => {
    await client.delete(`/treatments/${id}`)
  },
}